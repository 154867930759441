import React from "react"

import styled from 'styled-components';
import theme from "../../../styles/theme";

import { FaRegCheckSquare } from 'react-icons/fa';

const StyledWrapper = styled.div`
  padding-top: 87px;
`;

const StyledHeader = styled.h2`
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  margin: 30px 0px 50px 0px;;
`;

const StyledList = styled.ul`
  list-style-type: square;

  li {
    font-size: 0.8rem;
    border-bottom: 1px solid ${theme.colors.lightGray};
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
`;

const StyledIcon = styled(FaRegCheckSquare)`
  text-align: center;
  font-size: 2rem;
  margin-top: 20px;
  width: 100%;
  color: ${theme.colors.purple};
`;

const Information = () => (
  <StyledWrapper>
    <StyledIcon />
    <StyledHeader>Mierzalne efekty wdrożenia kompleksowej logistyki szpitalnej.</StyledHeader>
    <StyledList>
      <li>Spełnienie aktualnych wymagań prawnych w zakresie prawa farmaceutycznego, praw pacjenta oraz informatyzacji procesów medycznych.</li>
      <li>Zwiększenie wiarygodności i dokładności danych wprowadzanych do systemów informatycznych.</li>
      <li>Skrócenie czasu wykonywania operacji rejestracyjnych, magazynowych i dystrybucyjnych.</li>
      <li>Zautomatyzowanie wykonywanych czynności magazynowych i transportowych.</li>
      <li>Zapewnienie pełnej kontroli nad przepływem towarów dzięki identyfikacji komputerowej każdej partii towarów.</li>
      <li>Pełna i aktualna informacja o lokalizacji wszystkich pozycji poszczególnych asortymentów towarów.</li>
      <li>Łatwe i przejrzyste planowanie i zarządzanie zapasami.</li>
      <li>Likwidacja papierowych dokumentów związanych z obiegiem informacji i towarów.</li>
      <li>Ograniczenie czynności związanych z weryfikacją papierowych dokumentów w magazynach.</li>
      <li>Bieżąca kontrola przebiegu procesów na magazynach i w transporcie szpitalnym.</li>
      <li>Automatyczna kontrola terminów przydatności poszczególnych produktów.</li>
      <li>Eliminacja większości błędów ludzkich , w obszarze identyfikacji ilościowej i jakościowej, szczególnie istotnej przy gospodarce lekami i materiałami medycznymi, gdzie pomyłka może oznaczać śmierć pacjenta.</li>
      <li>Zmniejszenie poziomu kradzieży produktów w całym systemie.</li>
      <li>Szybkie i łatwe procesy inwentaryzacyjne, szczególnie ważne przy dużej rotacji personelu.</li>
      <li>Przejrzyste i łatwe księgowanie kosztów jednostkowych zużyć w procesach medycznych, z możliwością rejestracji kosztu „na pacjenta” w czasie rzeczywistym.</li>
      <li>Oszczędności finansowe w obszarze gospodarki lekami i procesami logistycznymi na poziomie 15% – 30% , w porównaniu do tradycyjnych systemów magazynowania i transportu, opartych na dokumentacji papierowej.</li>
    </StyledList>
  </StyledWrapper>
)

export default Information
