import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero";
import Information from "../components/Implementation/Sections/Information";

const ImplementationPage = () => (
  <Layout>
    <SEO title="Wdrożenia" />
    <Hero title="Wdrożenia"/>
    <Information />
  </Layout>
)

export default ImplementationPage
